import React, { useEffect, useState } from "react";
import {
  RiAddCircleFill,
  RiArrowDownSLine,
  RiMenuAddLine,
} from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import TaskListLoader from "../components/loaders/TaskListLoader";
import Card from "../components/UI/card/Card";
import { useParams } from "react-router-dom";
import axios from "axios";
import ClientNameLoader from "../components/loaders/ClientNameLoader";
import AddNewClientModal from "../components/modals/AddNewChecklistModal";
import { Link } from "react-router-dom";
import { ImBin2 } from "react-icons/im";
import Swal from "sweetalert2";

const ClientIntro = () => {
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const { id } = useParams();
  const [showDropDown, setShowDropDown] = useState(false);
  const [checkListLoading, setCheckListLoading] = useState(true);
  const [clientLoading, setClientLoading] = useState(false);
  const [client, setClient] = useState();
  const [checklists, setChecklists] = useState([]);
  const [allChecklists, setAllChecklists] = useState([]);

  const deleteChecklist = (clientChecklistId) => {
    Swal.fire({
      title: "Are you sure you want to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed){
        axios
                  .post(
                    baseUrl + "deleteClientChecklist",
                    {
                      'client_checklist_id' : clientChecklistId
                    },
                    {
                      headers: {
                        accesstoken: "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                        Authorization: "Basic YWRtaW46MTIzNA==",
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.status == "200") {
                      Toast.fire({
                        icon: "success",
                        title: "Checklist Deleted Successfully.",
                      });
                      document.getElementById('client_checklist_id_'+clientChecklistId).remove();
                    }else{
                      Toast.fire({
                        icon: "error",
                        title: "Checklist Already in progress.",
                      });
                    }
                  });
      }
    });
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  useEffect(() => {
    setClientLoading(true);
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .get(baseUrl + "get-client/" + id, {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        setClient(response.data.data[0]);
      });

    axios
      .get(baseUrl + "get-all-checklists", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        setAllChecklists(response.data.data);
        setClientLoading(false);
      });

    setCheckListLoading(true);
    axios
      .get(baseUrl + "get-assigned-checklists?client_id=" + id, {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        setChecklists(response.data.data);
        setCheckListLoading(false);
        document.getElementById("fullPageLoader").classList.add("d-none");

      });
  }, [id]);

  const addNewChecklistDB = (state) => {
    // var Newstate = [...checklists, state];
    setChecklists(state);
  };
  const addNewChecklist = () => {
    document.getElementById("addNewCheckListModal").classList.toggle("d-none");
  };
  return (
    <div className="w-100">
      <nav className="w-100 client_overview__nav ">
        <div className="d-flex w-100">
          <h2>{clientLoading ? <ClientNameLoader /> : client?.name}</h2>
          <div className="p-relative">
            <FiSettings
              onClick={() => {
                setShowDropDown(!showDropDown);
              }}
              className="ml-1 client_nav__dropdown"
            />
            {showDropDown ? (
              <div
                className="p-absolute client__name__nav"
                onBlur={() => {
                  setShowDropDown(false);
                }}
              >
                <ul>
                  <li>Edit team settings</li>
                  <li>Add new Checklist</li>
                  <li>Edit client details</li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <span className="ml-auto" title="Add New Team Member">
            Add Team Members
          </span>
          <RiAddCircleFill
            className="float-end svg_blue ml-2"
            title="Add New Team Member"
          /> */}
        </div>
      </nav>
      <section className="client_details__section">
        <Card className="mb-5 p-5">
          {!clientLoading ? (
            <div>
              <h2 className="mb-3">Client Details</h2>
              <p className="mb-2 d-flex ">
                <span className="w-50">Contact : +91 {client?.contact} </span>
                <span>Site Address : {client?.site_address} </span>
              </p>
              <p className="mb-2 d-flex  ">
                <span className="w-50">Address : {client?.address}</span>{" "}
                <span>Site location: {client?.site_location}</span>
              </p>
              <p className="mb-2 d-flex  ">
                <span className="w-50">
                  Site Information : {client?.site_address}
                </span>{" "}
                <span>
                  Site Contact Number: +91 {client?.site_contact_number}
                </span>
              </p>
              <p className="mb-2 d-flex  ">
                <span className="w-50">
                  Customer Type : {client?.customer_type ? client?.customer_type : 'NA'}
                </span>{" "}
                <span>
                  Refered By:  {client?.reffered_by ? client?.reffered_by : 'NA'}
                </span>
              </p>
              <p className="mb-2 d-flex  ">
                <span className="w-50">
                  GST No. : {client?.gst_no ? client?.gst_no : 'NA'}
                </span>{" "}
                <span>
                  VAT N0. :  {client?.vat_no ? client?.vat_no : 'NA'}
                </span>
              </p>
              <p className="mb-2 d-flex  ">
                <span className="w-50">
                  PAN No. : {client?.pan_no ? client?.pan_no : 'NA'}
                </span>{" "}
                <span>
                  Category: {client?.category ? client?.category : 'NA'}
                </span>
              </p>
              {/* <p className="mb-2 d-flex ">
              <span className="w-50">Site Contact Person : Random Name</span>{" "}
            </p> */}
            </div>
          ) : (
            <TaskListLoader />
          )}
        </Card>
        {/* <Card className="mb-5 p-5">
          <h2>Members (3)</h2>
        </Card> */}
        <Card className="p-5">
          <h2 className="mb-4">Client CheckLists </h2>
          {checkListLoading ? (
            <TaskListLoader />
          ) : (
            <div>
              <div
                className="d-flex mb-1 fw-500 cursor-pointer hover-color-grey p-2"
                onClick={addNewChecklist}
              >
                <RiMenuAddLine className=" mr-3 client_page__add_new_checklist" />
                Add New Checklist
              </div>
              {checklists?.map((value) => {
                return (
                  <div
                    key={"cient_key_" + value.id}
                    id={"client_checklist_id_" + value.id}
                    className="d-flex mb-1 fw-500 cursor-pointer hover-color-grey"
                  >
                    <Link
                      to={"/client/checklist/" + id + "/" + value.checklist_id}
                      key={value.id}
                      className={
                        "d-flex w-90 mb-1 fw-500 cursor-pointer hover-color-grey p-2"
                      }
                    >
                      <RiMenuAddLine className="client_page__add_new_checklist mr-3" />
                      {value.name}
                      <span className="ml-5">Completed: {value.completeTasks}/{value.totalTasks}</span>
                      <span className="ml-auto">
                        Assignee: {value.user_name}
                        {/* {value.assignee_id == 814 ? "Demo QA" : ""}
                        {value.assignee_id == 810 ? "Demo Installer" : ""}
                        {value.assignee_id == 809 ? "Demo Surveyor" : ""} */}
                      </span>
                    </Link>

                    <span
                      onClick={() => deleteChecklist(value.id)}
                      className="ml-2"
                    >
                      {<ImBin2 />}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </Card>
      </section>
      <AddNewClientModal
        allChecklists={allChecklists}
        id={id}
        addNewChecklist={(state) => addNewChecklistDB(state)}
      />
    </div>
  );
};

export default ClientIntro;
