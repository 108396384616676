import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import classes from "./components/UI/tasks/Task.module.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from "./components/sidebar/Sidebar";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Routes, Route } from "react-router-dom";
import ClientCheckLists from "./components/ClientCheckLists";
import User from "./components/User";
import Home from "./pages/Home";
import Task from "./pages/Task";
import Reports from "./pages/Reports";
import UserTask from "./pages/UserTask";
import UserDashboard from "./pages/UserDashboard";
import ClientIntro from "./pages/ClientIntro";
import Sidebarclasses from "./components/sidebar/Sidebar.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ClientActions } from "./store/client-slice";
import ChecklistTasks from "./pages/ChecklistTasks";
import TaskListLoader from "./components/loaders/TaskListLoader";
import GroundChecklistTasks from "./pages/GroundChecklistTasks";
import axios from "axios";
import Select from "react-select";
import AddNewClientModal from "./components/modals/AddNewClientModal";
import { Puff } from "react-loader-spinner";
import TotalTasks from "./pages/TotalTasks";
import IncompleteTasks from "./pages/IncompleteTasks";
function App() {
  // const baseUrl = "http://127.0.0.1:8000/api/";
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeDetailsModal = () => {
    document.getElementById("edit_task_details__div").classList.add("d-none");
  };

  const userAuth = useSelector((state) => state.auth.auth);
  const authCheck = () => {
    if (!userAuth) {
      console.log("checking");
      return navigate("/");
    } else {
      console.log();
      return navigate("/home");
    }
  };
  useEffect(() => {
    // authCheck();
  }, []);

  var selectValues = [];
  const returnValues = (e) => {
    selectValues = [];
    selectValues = e;
  };

  const showNewClientModal = () => {
    document.getElementById("add_client_modal").classList.toggle("d-none");
  };

  return (
    <Fragment>
      <div className="todo_list_main_page">
        {userAuth && <Sidebar />}

        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/client/:id" element={<ClientCheckLists />} />
          <Route exact path="/user/:id" element={<User />} />
          <Route exact path="/task" element={<Task />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/user-task" element={<UserTask />} />
          <Route exact path="/total-tasks" element={<TotalTasks />} />
          <Route exact path="/incomplete-tasks" element={<IncompleteTasks />} />
          <Route
            exact
            path="/client/checklist/:client_id/:checklist_id"
            element={<ChecklistTasks />}
          />

          <Route
            exact
            path="/user/checklist/:client_id/:checklist_id"
            element={<GroundChecklistTasks />}
          />
          <Route exact path="/user-dashboard" element={<UserDashboard />} />
          <Route exact path="/client/overview/:id" element={<ClientIntro />} />
        </Routes>
      </div>

      <AddNewClientModal />

      <div
        className={classes.task_details + " d-none"}
        id="edit_task_details__div"
      >
        <div className={classes.task_details__topbar}>
          {/* <span>{"Mark Completed"}</span>{" "} */}
          <span>
            <AiOutlineCloseCircle onClick={closeDetailsModal} />
          </span>
        </div>
        <div className="mt-3 p-20px">
          <h2 id="task_details_modal_title"> Remark Details</h2>
        </div>
        <div className="d-none" id="details_loader">
          <TaskListLoader />
        </div>
        <div id="details_remarks_div" className="p-20px"></div>
      </div>
      <div id="fullPageLoader" className="d-none">
        <div>
          <Puff
            height="100"
            width="100"
            radisu={1}
            color="#14a19d"
            ariaLabel="puff-loading"
            wrapperStyle={{ margin: "auto", display: "inline-block" }}
            wrapperClass=""
            visible={true}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default App;
