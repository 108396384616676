import React, { Fragment } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import classes from "./Home.module.css";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, BarChart } from "recharts";
import Card from "../components/UI/card/Card";
const Home = () => {
    const barchartData = [
        {
          "name": "Page A",
          "uv": 4000,
          "pv": 2400
        },
        {
          "name": "Page B",
          "uv": 3000,
          "pv": 1398
        },
        {
          "name": "Page C",
          "uv": 2000,
          "pv": 9800
        },
        {
          "name": "Page D",
          "uv": 2780,
          "pv": 3908
        },
        {
          "name": "Page E",
          "uv": 1890,
          "pv": 4800
        },
        {
          "name": "Page F",
          "uv": 2390,
          "pv": 3800
        },
        {
          "name": "Page G",
          "uv": 3490,
          "pv": 4300
        }
      ];
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  return (
    <div className={classes.home_page_section}>
      <div className="d-flex mb-60 align-item-end">
        <h2><AiOutlineGlobal/></h2>
        <h1 style={{marginRight: '5px'}}>Dashboard</h1>
        <p>Control Panel</p>
      </div>
      <section className="mb-60">
        <div className="d-grid-50-50">
          <Card className=" p-5">
            <h3 className="mb-30">Upcoming tasks by assignee this week</h3>
            <BarChart style={{ margin: "auto" }}  width={500} height={350} data={barchartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" />
              <Bar dataKey="uv" fill="#82ca9d" />
            </BarChart>
          </Card>
          <Card className=" p-5">
            <h3 className="">Total tasks by task status</h3>
            <PieChart style={{ margin: "auto" }} width={320} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={120}
                outerRadius={150}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </Card>
          
        </div>
      </section>
      <section className="mb-60">
        <h2 className="mb-30">Projects</h2>
        <div className="d-grid-25-25-25-25">
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">No. of active sites / projects</h3>
            <h2>50</h2>
          </Card>
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">No. of active projects in progress</h3>
            <h2>50</h2>
          </Card>
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">No. 0f active projects completed</h3>
            <h2>15</h2>
          </Card>
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">No. of project on hold</h3>
            <h2>0</h2>
          </Card>
        </div>
      </section>
      <section className="mb-60">
        <h2 className="mb-30">Work Force</h2>
        <div className="d-grid-33-33-33">
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">Total no. of surveyors</h3>
            <h2>10</h2>
          </Card>
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">No. of surveyors present on field</h3>
            <h2>50</h2>
          </Card>
          <Card className="card_hover p-5 text-center">
            <h3 className="mb-15">
              Average no. of the project assigned per surveyor
            </h3>
            <h2>5</h2>
          </Card>
        </div>
      </section>
      
    </div>
  );
};

export default Home;
