import React from "react";
import { Fragment } from "react";
import Card from "../components/UI/card/Card";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
const TotalTasks = () => {
    const [data, setData] = useState([]);
    //   const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";
   useEffect(()=>{
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .get(baseUrl + "getTotalTaskList", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        setData(response.data.data);
        document.getElementById("fullPageLoader").classList.add("d-none");
        
      });
    
   }, []) 
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
        color: "#31519a",
        fontWeight: 400,
      },
    },
    headRow: {
      style: {
      },
    },
    headCells: {
      style: {
        color: "#31519a",
        fontWeight: 600,
        fontSize: '15px',
        "&:not(:last-of-type)": {
        },
      },
    },
    cells: {
      style: {
        
      },
    },
  };

  const columns = [
    {
      name: "Checklist Name",
      selector: (row) => row.checklist_name,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
      sortable: true,
    },
    {
      name: "Task",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Assignee",
      selector: (row) => row.assignee,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];

  
  return (
    <Fragment>
      <div className="px-5 py-1 w-100">
      
        <section className="mt-5">
          <Card>
          <h2 className="p-5"> Tasks / Total Tasks </h2>

            <div className="px-5">
              <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                pagination
              />
            </div>
          </Card>
        </section>
      </div>
    </Fragment>
  );
};

export default TotalTasks;
