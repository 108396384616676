import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import UserTask from "../components/UI/tasks/UserTask";
import Card from "../components/UI/card/Card";
const GroundChecklistTasks = () => {
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";
  const [clientDetials, setClientDetials] = useState([]);

  const [tasks, setTasks] = useState([]);
  const { client_id, checklist_id } = useParams();
  useEffect(() => {
    axios
      .post(
        baseUrl + "getGrounduserTasks",
        {
          client_id,
          checklist_id,
          user_id: localStorage.getItem("user_id"),
        },
        {
          headers: {
            accesstoken: localStorage.getItem("user_token"),
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setTasks(response.data.data);
        return;
      });

    axios
      .post(
        baseUrl + "getChecklistAssignedDetails",
        {
          client_id,
          checklist_id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setClientDetials(response.data.data[0]);
        return;
      });
  }, [checklist_id, client_id]);
  return (
    <div className="ml-15 mr-15">
      <Card className="mb-2 p-2">
        {
          <h4>
            {clientDetials?.checklist_name} /{" "}
            <span className="font-size-16 text-muted">
              {clientDetials?.client_name}
            </span>
          </h4>
        }{" "}
      </Card>
      {tasks?.map((value) => {
        return (
          <UserTask
            key={'user_task'+value.td_id}
            title={value.title}
            status={value.status}
            id={value.td_id}
          />
        );
      })}
    </div>
  );
};

export default GroundChecklistTasks;
