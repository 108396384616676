import React, { Fragment, useEffect, useState } from "react";
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import Task from "../tasks/Task";
import classes from "./Section.module.css";
import axios from "axios";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
const Section = (props) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  let { id } = useParams();
  // const baseUrl = "http://206.189.141.201/api/";
  // const baseUrl = "http://127.0.0.1:8000/api/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const [users, setUsers] = useState([]);
  const [taskOpen, setTaskOpen] = useState(false);
  const [sectionCompleted, setSectionCompleted] = useState(0);
  const [tasks, setTasks] = useState([]);

  const ChangeTaskState = () => {
    setTaskOpen(!taskOpen);
  };

  const checkForNull = (e) => {
    if (!e.target.innerHTML) {
      e.target.innerHTML = "New Task";
    }

    const checkListId = e.target.dataset.id;
    axios
      .patch(baseUrl + "checklist/" + checkListId, {
        checklist: e.target.innerHTML,
      })
      .then((response) => {
        if (response.statusText == "OK") {
          Toast.fire({
            icon: "success",
            title: "Check List Updated Successfully",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Unable to Update",
          });
        }
      });
  };
  const addTask = (e) => {
    setTaskOpen(1);
    axios
      .post(baseUrl + "checklisttodo", {
        checklistid: props.id,
        todo: "New Task",
      })
      .then((response) => {
        axios
          .post(baseUrl + "todoDetails", {
            todo_id: response.data.id,
            clientid: id,
            assignee: null,
            status: 0,
            due_date: null,
          })
          .then((details) => {
            axios
              .post(baseUrl + "ClientChecklistRemark", {
                todoid: response.data.id,
                clientid: id,
              })
              .then((remarks) => {
                setTasks((prevState) => [
                  ...prevState,
                  {
                    id: response.data.id,
                    title: response.data.todo,
                    assignee: "",
                    status: details.data.status,
                    detailsid: details.data.id,
                    remarksid: remarks.data.id,
                  },
                ]);
              });
          });
      });
  };

  const checkIfCompleted = () => {
    const sectionTasks = document.getElementById(props.id).lastChild;
    const totalChild = sectionTasks.childNodes.length;
    console.log(totalChild);
    var count = 2;

    sectionTasks.childNodes.forEach((value) => {
      if (!value.getAttribute("data-completed")) {
        // count--;
      } else {
        count++;
      }
    });
    if (count >= totalChild) {
      setSectionCompleted(1);
      props.showNext(sectionCompleted);
    } else {
      setSectionCompleted(0);
      props.showNext(sectionCompleted);
    }
  };

  useEffect(() => {

    axios.get(baseUrl + "checklisttodo").then((response) => {
      // console.log(response.data);
      var tasksNew = [];
      response.data.map((task) => {
        if (props.id == task.checklistId) {
          axios.get(baseUrl + "todoDetails/" + task.id).then((details) => {
            if (details.data.clientid == id) {
              if (details.data.assignee) {
                axios
                  .get(baseUrl + "users/" + details.data.assignee)
                  .then((response) => {
                    axios
                      .get(
                        baseUrl +
                          "getChecklistTodoRemarks/" +
                          id +
                          "/" +
                          task.id
                      )
                      .then((remarks) => {
                        if (localStorage.getItem("user_role") == 1) {
                          tasksNew.push({
                            key: "taskKey" + task.id,
                            id: task.id,
                            title: task.todo,
                            assignee: details.data.assignee
                              ? {
                                  value: response.data.id,
                                  label: response.data.name,
                                }
                              : "",
                            dueDate: details.data.due_date,
                            status: details.data.status,
                            detailsid: details.data.id,
                            remarksid: remarks.data.id,
                            remarks: remarks.data.remark,
                          });
                        } else if (
                          localStorage.getItem("user_id") == response.data.id
                        ) {
                          tasksNew.push({
                            key: "taskKey" + task.id,
                            id: task.id,
                            title: task.todo,
                            assignee: details.data.assignee
                              ? {
                                  value: response.data.id,
                                  label: response.data.name,
                                }
                              : "",
                            dueDate: details.data.due_date,
                            status: details.data.status,
                            detailsid: details.data.id,
                            remarksid: remarks.data.id,
                            remarks: remarks.data.remark,
                          });
                        }
                      });
                  });
              } else {
                if (localStorage.getItem("user_role") == 1) {
                  axios
                    .get(
                      baseUrl + "getChecklistTodoRemarks/" + id + "/" + task.id
                    )
                    .then((remarks) => {
                      tasksNew.push({
                        key: "taskKey" + task.id,
                        id: task.id,
                        title: task.todo,
                        assignee: "",
                        dueDate: details.data.due_date,
                        status: details.data.status,
                        detailsid: details.data.id,
                        remarksid: remarks.data.id,
                        remarks: remarks.data.remark,
                      });
                    });
                }
              }
            }
          });
        }
      });
      setTasks(tasksNew);
      var NewUsers = [];

      axios.get(baseUrl + "users").then((response) => {
        response.data.map((user) => {
          NewUsers.push({ value: user.id, label: user.name });
        });
      });
      setUsers(NewUsers);
    });
  }, [id]);
  return (
    <Fragment>
      <div className={classes.task_section} id={props.id}>
        <h3 className={classes.task_section__title + " d-flex"}>
          <span onClick={ChangeTaskState} className={classes.section_icon}>
            {taskOpen ? <AiFillCaretDown /> : <AiFillCaretRight />}
          </span>
          <span
            contentEditable={
              localStorage.getItem("user_role") == 1 ? "true" : "false"
            }
            suppressContentEditableWarning={true}
            onBlur={checkForNull.bind(this)}
            className={classes.taskTitle}
            data-id={props.id}
          >
            {props.title}
          </span>
          {localStorage.getItem("user_role") == 1 ? (
            <IoMdAddCircleOutline
              className={classes.addEventIcon}
              data-taskid={props.id}
              onClick={addTask.bind(this)}
            />
          ) : (
            ""
          )}
        </h3>
        <div
          className={"list-section-tasks " + (taskOpen ? " " : "d-none")}
          id={props.task_id}
        >
          {tasks.map((task) => {
            return (
              <Task
                key={task.key}
                id={task.id}
                title={task.title}
                assignee={task.assignee ? task.assignee : ""}
                dueDate={task.dueDate}
                status={task.status}
                users={users}
                detailsid={task.detailsid}
                remarksid={task.remarksid}
                remark={task.remarks}
              />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default Section;
