import React, { Fragment, useEffect, useState } from "react";
import Logo from "../../assets/woco_logo.png";
import classes from "./Sidebar.module.css";
import { GrHomeRounded } from "react-icons/gr";
import { IoMdAdd } from "react-icons/io";
import { GrTasks } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Trail from "../../assets/trail.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Card from "../UI/card/Card";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ClientActions from "../../store/client-slice.js";
import LinkLoader from "../loaders/LinkLoader.js";
import {IoArrowBackSharp} from 'react-icons/io5';
const Sidebar = (props) => {
  const dispatch = useDispatch();
  const [LoadingClients, setLoadingClients] = useState(false);
  const showNewClientModal = () => {
    document.getElementById("add_client_modal").classList.toggle("d-none");
  };
  // const baseUrl = "http://206.189.141.201/api/";
  // const baseUrl = "http://wocollp.workcompanion.co/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";

  const Clients = useSelector((state) => state.clients);
  var client = [];
  const [clients, setClients] = useState(client);
  useEffect(() => {
    setLoadingClients(true);
    axios
      .get(baseUrl + "get-all-clients", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        response.data.data.map((value) => {
          dispatch(
            ClientActions.actions.addClient({
              key: "client" + value.id,
              value: value.id,
              label: value.name,
            })
          );
        });
        setLoadingClients(false);
      });
  }, []);

  var selectValues = [];
  const returnValues = (e) => {
    selectValues = [];
    selectValues = e;
  };
  const navigate = useNavigate();

  return (
    <Fragment>
      <section className={classes.sidebar_section}>
        <img src={Logo} className={classes.sidebar_logo} />
        <div>
          <ul>
            <li>
              <Link to={"/home"}>
                <GrHomeRounded /> Home
              </Link>
            </li>
            <li>
              <Link to={"/task"}>
                <GrTasks />
                Tasks
              </Link>
            </li>

            <li>
              <Link to={"/reports"}>
                <HiOutlineDocumentReport /> Reports
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <p>Clients</p>
          {LoadingClients ? (
            <LinkLoader />
          ) : (
            <ul>
              {Clients.map((value) => {
                return (
                  <Link key={value.key} to={"/client/overview/" + value.value}>
                    <li>{value.label}</li>{" "}
                  </Link>
                );
              })}
            </ul>
          )}
          {!LoadingClients && (
            <ul>
              <li style={{cursor:'pointer'}} onClick={showNewClientModal}>
                <IoMdAdd />
                Add Clients
              </li>
            </ul>
          )}
        </div>
      </section>
      <section className={classes.mobile_nav}>
        <img src={Trail} className={classes.mobile_trail} />
        <div className={classes.mobile_nav__backdrop_blue}></div>
        {/* <div className={classes.my_task}>AUTOMATED CHECKLIST </div> */}
        <Card className={classes.mobile_nav__links}>
          <NavLink onClick={() => navigate(-1)}><span className="color-blue">Go Back</span> </NavLink>
          <NavLink
            to="/user-task"
            className={({ isActive }) =>
              isActive ? "active_nav_mobile" : "inactive_nav_mobile"
            }
          >
            MY TASK
          </NavLink>
          <NavLink
            to="/user-clients"
            className={({ isActive }) =>
              isActive ? "active_nav_mobile" : "inactive_nav_mobile"
            }
          >
            SITES
          </NavLink>
          <NavLink
            to="/user-dashboard"
            className={({ isActive }) =>
              isActive ? "active_nav_mobile" : "inactive_nav_mobile"
            }
          >
            REPORTS
          </NavLink>
        </Card>
      </section>
    </Fragment>
  );
};

export default Sidebar;
