import React, { useEffect } from "react";
import Section from "./UI/section/Section";
import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
const ClientCheckLists = () => {
  // const baseUrl = "http://206.189.141.201/api/";
  // const baseUrl = "http://127.0.0.1:8000/api/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  let { id } = useParams();
  var check__List = [
    {
      id: 2,
      title: "CHECKLIST SKYLIGHT/FLOOR GLASS SURVEY",
      completed: "0",
      show: "1",
      tasks: [
        {
          id: "task_1",
          title: "Is raised platform available to fix the glass",
          assignee: [
            { value: "Aditya", label: "Aditya" },
            { value: "Mayank", label: "Mayank" },
          ],
          dueDate: "2022-09-22",
        },
        {
          id: "task_2",
          title: "is the slope aligned with existing floor slope",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_3",
          title: "is the location of pipes confirmed from customer walls/floor",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_4",
          title: "Is location of pipes marked in drawings",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_5",
          title: "Is any projection required as per customer",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_6",
          title: "Is the framing of MS or SS",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_7",
          title: "Is hardware finish and type discussed",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_8",
          title: "Confirmation to customer about tape visibility.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_9",
          title: "Electric point location for tools",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_10",
          title: "Is site excessable for material delivery	",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_11",
          title: "Can glass be lifted through staircase	",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_12",
          title: "what is the finish of wall/check if it is dry cladding",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_13",
          title: "Is there any provision of Z section fixing",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",

          dueDate: "2022-09-22",
        },
      ],
    },
    {
      id: 1,
      title: "CHECKLIST SKYLIGHT/FLOOR GLASS ",
      completed: "0",
      show: "0",
      tasks: [
        {
          id: "task_21",
          title: "Is water testing done.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_22",
          title: "Is drain and slope of glass functioning properly.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_23",
          title: "Is silicon work done properly as per specification",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_24",
          title: "Is silicon finish proper.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_25",
          title: "Is sealing tape or pressure plate fixed as per drawing",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_26",
          title: "Is Glass corner protection angle fixed.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_27",
          title: "Is all work carried out as per drawing.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_28",
          title:
            "Is flashing between wall and glass fixed properly with silicon",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_29",
          title: "Is glass sticker from glass removed.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
        {
          id: "task_20",
          title: "Is glass cleaning done.",
          assignee: [{ value: "Aditya", label: "Aditya" }],
          dueDate: "2022-09-22",
        },
      ],
    },
  ];
  var checklist_id = [];

  const getClients = () =>{
    axios.get(baseUrl + "ChecklistClient").then((response) => {
      response.data.map((value) => {
        if (value.clientid == id) {
          checklist_id = [...checklist_id, value.checklistId];
        }
      });
    });
  }
  const getCheckLists  = () =>{
    var newCheckList = [];

    axios.get(baseUrl + "checklist").then((response) => {
      response.data.map((value) => {
        if (checklist_id.includes(value.id)) {
          newCheckList.push({
            key: value.id,
            id: value.id,
            title: value.checklist,
            completed: "0",
            show: "0",
            tasks: [],
          });
        }
      });
      setCheckList(newCheckList);
    });
  }
  const [checkList, setCheckList] = useState([]);
  useEffect(() => {
    getClients();
    getCheckLists();
  }, [id]);
  const addNewCheckList = () => {
    var newCheckList = [...checkList];
    axios
      .post(baseUrl + "checklist", {
        checklist: "New Checklist",
      })
      .then((response) => {
        axios
          .post(baseUrl + "ChecklistClient", {
            checklistId: response.data.id,
            clientid: +id,
          })
          .then((response) => {
            setCheckList((prevState) => [
              ...prevState,
              {
                key: response.data.id,
                id: response.data.id,
                title: "New Check List",
                completed: "0",
                show: "0",
                tasks: [],
              },
            ]);
          });
      });
  };
  return (
    <div id="check_list_div" className="flex-2 p-2">
      {localStorage.getItem("user_role") == 1 ? (
        <button className="btn" onClick={addNewCheckList}>
          Add New Check List
        </button>
      ) : (
        ""
      )}
      {checkList.map((result) => {
        return (
          <Section
            key={result.key}
            title={result.title}
            id={result.id}
            tasks={result.tasks}
            showNext={1}
          />
        );
      })}
    </div>
  );
};

export default ClientCheckLists;
