import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
const AddNewClientModal = (props) => {
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const [qa_assignee, setQaAssignee] = useState([]);
  const [survey_assignee, setSurveyAssignee] = useState([]);
  const [installer_assignee, setInstallerAssignee] = useState([]);
  const [usersOptions, setUserOptions] = useState(survey_assignee);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(()=>{
    axios
    .post(
      baseUrl + "getDepartmentChecklistUsers",{
        department_id: '1',
      },
      {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      }
    )
    .then((response) => {
      setSurveyAssignee(response.data.data);
      return;
    });
    axios
    .post(
      baseUrl + "getDepartmentChecklistUsers",{
        department_id: '2',
      },
      {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      }
    )
    .then((response) => {
      setInstallerAssignee(response.data.data);
      return;
    });
    axios
    .post(
      baseUrl + "getDepartmentChecklistUsers",{
        department_id: '3',
      },
      {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      }
    )
    .then((response) => {
      setQaAssignee(response.data.data);
      return;
    });
    axios
    .get(
      baseUrl + "getAllUsers",
      {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      }
    )
    .then((response) => {
      setAllUsers(response.data.data);
      return;
    });
  }, []);

  // const id  = location.pathname.split("/").pop();
  const client_id = props.id;
  const [addChecklistModalState, setAddChecklistModalState] =
    useState("default");
  const [newChecklistType, setNewChecklistType] = useState();
  const [newChecklistAssignee, setNewChecklistAssignee] = useState();
  const [allUsers, setAllUsers] = useState();
  const [templateList, setTemplateList] = useState();
  const [templateAssignee, setTemplateAssignee] = useState();

  const changeCheckListModalState = () => {
    if (addChecklistModalState === "default") {
      document.getElementById("addNewCheckListModal").classList.add("d-none");
    } else {
      setAddChecklistModalState("default");
    }
  };
  const showNewChecklistModal = () => {
    document.getElementById("addNewCheckListModal").classList.toggle("d-none");
  };

  const addNewChecklist = (e) => {
    e.preventDefault();
    var assignee = newChecklistAssignee.value;
    var checklist_type = newChecklistType.value;
    var checklist_name = document.getElementById(
      "addNewChecklistNameInput"
    ).value;

    axios
      .post(
        baseUrl + "assign-new-checklist",
        {
          client_id,
          assignee,
          checklist_name,
          checklist_type,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        var Checklist = response.data.data;
        props.addNewChecklist(Checklist);    
        Toast.fire({
          icon: "success",
          text: "Checklist Created Successfully",
        });
        document.getElementById("addNewCheckListModal").classList.add("d-none");
        return;
      });
  };
  var allChecklists = [];
  props.allChecklists.map((value) => {
    allChecklists.push({ value: value.id, label: value.name });
  });
  const submitTemplateChecklist = (e) => {
    e.preventDefault();
    document.getElementById("addNewCheckListModal").classList.remove("d-none");

    axios
      .post(
        baseUrl + "assign-existing-checklist",
        {
          client_id,
          assignee: templateAssignee.value,
          checklist_id: templateList.value,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        // props.addNewChecklist(Checklist);
        var Checklist = response.data.data;
        props.addNewChecklist(Checklist);    
        document.getElementById("addNewCheckListModal").classList.remove("d-none");
        
        Toast.fire({
          icon: "success",
          text: "Checklist Created Successfully",
        });  
        document.getElementById("addNewCheckListModal").classList.add("d-none");
        return;
      });

  };
  const updateUsersOption = (value) => {
    if (value == 1) {
      setUserOptions(survey_assignee)
      // usersOptions = survey_assignee;
    } else if (value == 2) {
      setUserOptions(installer_assignee)
      // usersOptions = installer_assignee;
    } else {
      setUserOptions(qa_assignee);
    }
  };
  return (
    <div className="checklistModal d-none" id="addNewCheckListModal">
      <div className="d-flex justify-space-between ">
        <BiArrowBack
          className="font-size-30 close-icon"
          onClick={changeCheckListModalState}
        />
        <AiOutlineClose
          className="font-size-30 close-icon"
          onClick={showNewChecklistModal}
        />
      </div>
      {addChecklistModalState === "default" ? (
        <div id="addNewClientTypeDetails" className="text-center">
          <div className="mb-5">
            <h2 className="mb-1">Create New Checklist</h2>
            <p>How would you like to start</p>
          </div>
          <div>
            <div className="d-flex text-center justify-space-around">
              <div
                onClick={() => {
                  setAddChecklistModalState("blank");
                }}
              >
                <AiOutlinePlus className="addNewChecklistIcon mb-2" />
                <h3 className="mb-1">Blank Checklist</h3>
                <p>Start from scratch</p>
              </div>
              <div
                className="ml-5"
                onClick={() => {
                  setAddChecklistModalState("template");
                }}
              >
                <img
                  className="addNewChecklistIcon mb-2"
                  alt=""
                  src="https://d3ki9tyy5l5ruj.cloudfront.net/obj/2201b53b56249b2529f04482c2f2049dc1bf1c61/rocket.svg"
                />
                {/* <AiOutlinePlus className="" /> */}
                <h3 className="mb-1">Use Template</h3>
                <p>Add from existing lists</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {addChecklistModalState === "blank" ? (
        <div id="addNewClientTypeDetails" className="w-35">
          <div className="mb-5 text-center">
            <h2 className="mb-1">New Checklist</h2>
          </div>
          <form id="addNewChecklistForm">
            <div>
              <div className="mb-3">
                <label>Checklist Name</label>
                <input
                  placeholder="Checklist Name"
                  type="text"
                  name="checklist_name "
                  className="w-100"
                  id="addNewChecklistNameInput"
                />
              </div>
              <div className="d-flex justify-content-between">
                <div className="mr-3 w-50">
                  <label>Checklist Type</label>
                  <Select
                    name="checklist_type"
                    id="checklist__select"
                    onChange={(options) => {
                      setNewChecklistType(options);
                      updateUsersOption(options.value);
                    }}
                    options={[
                      { value: "1", label: "Survey" },
                      { value: "2", label: "Installation" },
                      { value: "3", label: "Quality Assurance" },
                    ]}
                  />
                </div>
                <div className="w-50">
                  <label>Checklist Assignee</label>
                  <Select
                    name="checklist_type"
                    onChange={(options) => {
                      setNewChecklistAssignee(options);
                    }}
                    id="checklist__select"
                    options={usersOptions}
                  />
                </div>
              </div>
            </div>
            <button className="btn btn-primary" onClick={addNewChecklist}>
              Add New Checklist
            </button>
          </form>
        </div>
      ) : (
        ""
      )}
      {addChecklistModalState === "template" ? (
        <div id="addNewClientTypeDetails" className="w-35">
          <div className="mb-5 text-center">
            <h2 className="mb-1">New Checklist</h2>
          </div>
          <form
            id="addTemplateChecklistForm"
            onSubmit={submitTemplateChecklist}
          >
            <div>
              <div className="d-flex justify-content-between">
                <div className="mr-3 w-50">
                  <label>Checklist Name</label>
                  <Select
                    name="checklist_type"
                    id="checklist__type"
                    onChange={(options) => {
                      setTemplateList(options);
                      updateUsersOption(options.value);

                    }}
                    options={allChecklists}
                  />
                </div>
                <div className="w-50">
                  <label>Checklist Assignee</label>
                  <Select
                    name="checklist_type"
                    id="checklist__assignee"
                    onChange={(options) => {
                      setTemplateAssignee(options);
                    }}
                    options={allUsers}
                  />
                </div>
              </div>
            </div>
            <button className="btn btn-primary">Add New Checklist</button>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddNewClientModal;
