import React, { Fragment, useEffect, useState } from "react";
import GroundWorkerClients from "../components/groundworkerclients/GroundWorkerClients";
import axios from "axios";
import TaskListLoader from '../components/loaders/TaskListLoader';
import Card from "../components/UI/card/Card";
const UserTask = () => {
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const [checklists, setCheckLists ] = useState([]);
   useEffect(() => {
    axios
      .get(
        baseUrl +
          "checklist/client/getClientsAssignedForUser?user_id=" +
          localStorage.getItem("user_id"),
        {
          headers: {
            accesstoken: localStorage.getItem("user_token"),
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setCheckLists(response.data.data);
      });
  }, []);
  return (
    <section className="w-100 p-2">
      {checklists?.length ? checklists?.map((value)=>{
        return <GroundWorkerClients key={value.id} checklists={value} />
      }) : <Card className="p-5"><h2>No Checklist Assigned</h2></Card>}
      
    </section>
  );
};

export default UserTask;
