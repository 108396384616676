import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const clientSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
      addClient: (state, actions) => { 
        state.push(actions.payload)
      }, 
      
    },
  });

export default clientSlice
export const ClientActions = clientSlice.actions