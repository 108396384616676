import React, { useEffect } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import classes from "./Home.module.css";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  BarChart,
} from "recharts";
import axios from "axios";
import Card from "../components/UI/card/Card";
import { Puff } from "react-loader-spinner";
import { useState } from "react";
import { Link } from "react-router-dom";
const Task = () => {
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";
  const [tasksByProject, SetTasksByProject] = useState([]);
  const [UpcomingTasksByUser, SetUpcomingTasksByUser] = useState([]);
  useEffect(() => {
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .get(baseUrl + "taskDetailsCount", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        if (response.data.status == "200") {
          document.getElementById("completedTasks").innerHTML =
            response.data.data.completedTask;
          document.getElementById("incompleteTasks").innerHTML =
            response.data.data.incompleteTask;
          document.getElementById("overDueTasks").innerHTML =
            response.data.data.overDueTask;
          document.getElementById("totalTasks").innerHTML =
            response.data.data.completedTask +
            response.data.data.incompleteTask;
        } else {
          document.getElementById("completedTasks").innerHTML = 0;
          document.getElementById("incompleteTasks").innerHTML = 0;
          document.getElementById("overDueTasks").innerHTML = 0;
          document.getElementById("totalTasks").innerHTML = 0;
        }
      });
    axios
      .get(baseUrl + "getIncompleteTasks", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        SetTasksByProject(response.data.data);
        document.getElementById("fullPageLoader").classList.add("d-none");
      });
    axios
      .get(baseUrl + "getUpComingTaskByAssignees", {
        headers: {
          accesstoken:
            "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        var data = response.data.data;
        for (var i = 0; i < data.length; i++) {
          data[i].value = parseInt(response.data.data[i].value);
        }
        SetUpcomingTasksByUser(data);
        document.getElementById("fullPageLoader").classList.add("d-none");
      });
  }, []);
  const barchartData = [
    {
      name: "CWS Techno",
      uv: 10,
    },
    {
      name: "Policy Bazar",
      uv: 8,
    },
    {
      name: "CWS Techno",
      uv: 10,
    },
    {
      name: "Policy Bazar",
      uv: 2,
    },
    {
      name: "CWS Techno",
      uv: 12,
    },
    {
      name: "Policy Bazar",
      uv: 6,
    },
  ];
  const data = [
    { name: "Group A", value: 4 },
    { name: "Group B", value: 3 },
    { name: "Group C", value: 3 },
    { name: "Group D", value: 2 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#2D458F"];
  return (
    <div className={classes.home_page_section}>
      <div className="d-flex mb-60 align-item-end">
        <h2>
          <AiOutlineGlobal />
        </h2>
        <h1 style={{ marginRight: "5px" }}>Dashboard</h1>
        <p>Control Panel</p>
      </div>
      <section className="mb-60">
        <h2 className="mb-30 ">Task</h2>
        <div className="d-grid-25-25-25-25">
          <Link to={"/total-tasks"}>
            <Card className="text-center p-5 card_active">
              <h3 className="mb-15">Total tasks</h3>
              <h2 className="color-gradient" id="totalTasks">
                <Puff
                  height="40"
                  width="40"
                  radisu={1}
                  color="#14a19d"
                  ariaLabel="puff-loading"
                  wrapperStyle={{ margin: "auto", display: "inline-block" }}
                  wrapperClass=""
                  visible={true}
                />
              </h2>
            </Card>
          </Link>

          <Card className="text-center p-5 card_hover">
            <h3 className="mb-15">Completed task</h3>
            <h2 className="color-gradient" id="completedTasks">
              <Puff
                height="40"
                width="40"
                radisu={1}
                color="#FFF"
                ariaLabel="puff-loading"
                wrapperStyle={{ margin: "auto", display: "inline-block" }}
                wrapperClass=""
                visible={true}
              />
            </h2>
          </Card>
          <Link to={"/incomplete-tasks"}>
            <Card className="text-center p-5 card_hover ">
              <h3 className="mb-15">Incomplete task</h3>
              <h2
                className="color-gradient hover_color_white"
                id="incompleteTasks"
              >
                <Puff
                  height="40"
                  width="40"
                  radisu={1}
                  color="#14a19d"
                  ariaLabel="puff-loading"
                  wrapperStyle={{ margin: "auto", display: "inline-block" }}
                  wrapperClass=""
                  visible={true}
                />
              </h2>
            </Card>
          </Link>
          <Card className="text-center p-5 card_hover">
            <h3 className="mb-15">Overdue tasks</h3>
            <h2 className="color-gradient" id="overDueTasks">
              <Puff
                height="40"
                width="40"
                radisu={1}
                color="#14a19d"
                ariaLabel="puff-loading"
                wrapperStyle={{ margin: "auto", display: "inline-block" }}
                wrapperClass=""
                visible={true}
              />
            </h2>
          </Card>
        </div>
      </section>
      <section className="mb-60">
        <div className="d-grid-50-50">
          <Card className=" p-5">
            <h3 className="mb-30">Incomplete tasks by project</h3>
            <BarChart
              style={{ margin: "auto" }}
              width={500}
              height={350}
              data={tasksByProject}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Bar dataKey="CWS Techno" fill="#8884d8" /> */}
              <Bar dataKey="incompleteTasks" fill="#12A19D" />
            </BarChart>
          </Card>
          <Card className="p-5">
            <h3 className="">Upcoming tasks by assignee this week</h3>
            <PieChart style={{ margin: "auto" }} width={320} height={400}>
              <Pie
                data={UpcomingTasksByUser}
                cx="50%"
                cy="50%"
                innerRadius={120}
                outerRadius={150}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {UpcomingTasksByUser.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Card>
        </div>
      </section>
      {/* <section className="mb-60">
        <div className="d-grid-50-50">
          <Card className=" p-5">
            <h3 className="mb-30">Total tasks by task status</h3>
            <BarChart
              style={{ margin: "auto" }}
              width={500}
              height={350}
              data={barchartData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="" fill="#82ca9d" />
            </BarChart>
          </Card>
          <Card className=" p-5">
            <h3 className="">Total tasks by task status</h3>
            <PieChart style={{ margin: "auto" }} width={320} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={120}
                outerRadius={150}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
                <Tooltip />
                <Legend />
              </Pie>
            </PieChart>
          </Card>
        </div>
      </section> */}
    </div>
  );
};

export default Task;
