import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { ClientActions } from "../../store/client-slice";
import Sidebarclasses from "../sidebar/Sidebar.module.css";
const AddNewClientModal = () => {
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const dispatch = useDispatch();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [ClientWork, setClientWorks] = useState([]);
  const [ClientType, setClientType] = useState();
  const [Category, setCategory] = useState();
  const addNewClient = (e) => {
    e.preventDefault();
    var name = document.getElementById("client_name_input").value;
    var contact = document.getElementById("client_contact_input").value;
    var address = document.getElementById("client_address_input").value;
    var site_name = document.getElementById("client_site_info_input").value;
    var site_contact_number =
      document.getElementById("site_contact_input").value;
    var site_address = document.getElementById("site_address_input").value;
    var site_location = document.getElementById("site_location_input").value;
    var gst_no = document.getElementById("gst_no").value;
    var vat_no = document.getElementById("vat_no").value;
    var pan_no = document.getElementById("pan_no").value;
    var reffered_by = document.getElementById("referred_by").value;
    console.log(Category);

    var customer_type = ClientType.value;
    var category = Category.value;
    axios
      .post(
        baseUrl + "add-client",
        {
          name,
          contact,
          address,
          site_name,
          site_address,
          site_contact_number,
          site_location,
          gst_no,
          pan_no,
          vat_no,
          reffered_by,
          customer_type,
          category,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        var NewClient = response.data.data[0];
        dispatch(
          ClientActions.addClient({
            key: "client" + NewClient.id,
            value: NewClient.id,
            label: NewClient.name,
          })
        );
        window.location.href = "/client/overview/" + NewClient.id;
        document.getElementById("add_client_modal").classList.add("d-none");
        return;
      });
  };

  const toggleClientModal = () => {
    document.getElementById("add_client_modal").classList.toggle("d-none");
  };
  return (
    <div className="checklistModal d-none" id="add_client_modal">
      <div className="d-flex justify-space-between ">
        <BiArrowBack
          className="font-size-30 close-icon"
          onClick={toggleClientModal}
        />
        <AiOutlineClose
          className="font-size-30 close-icon"
          onClick={toggleClientModal}
        />
      </div>

      <div className={"text-center"}>
        <h2>Add New Client</h2>
      </div>
      <div className="addNewClientDivForm">
        <form onSubmit={addNewClient} id="add_new_client_form">
          <div className="d-flex mt-3">
            <div className="w-50 mr-3">
              <label className="d-block">Client Name</label>
              <input
                className="w-100"
                type="text"
                placeholder="Client Name"
                id="client_name_input"
                required
                minLength={5}
              />
            </div>
            <div className="w-50">
              <label className="d-block">Client Contact</label>
              <input
                className="w-100"
                type="number"
                id="client_contact_input"
                placeholder="Client Contact Number"
                required
                min={1111111111}
                max={9999999999}
                minLength={10}
              />
            </div>
          </div>
          <div className="d-flex mt-3">
            <div className="w-50  mr-3">
              <label>Client Address</label>
              <input
                type="text"
                id="client_address_input"
                placeholder="Client Address"
                required
                className="w-100"
              />
            </div>
            <div className="w-50">
              <label>Site Name</label>
              <input
                className="w-100"
                type="text"
                id="client_site_info_input"
                placeholder="Site Name"
                required
              />
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="w-50 mr-3">
              <label>Site Address</label>
              <input
                className="w-100"
                type="text"
                id="site_address_input"
                required
                placeholder="Site Address"
              />
            </div>
            <div className="w-50">
              <label>Site location</label>

              <input
                className="w-100"
                type="text"
                id="site_location_input"
                placeholder="Site Geo :Location"
              />
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="w-50 mr-3">
              <label>Customer type</label>
              <Select
                options={[
                  { label: "REFERRED", value: "REFERRED" },
                  { label: "MARKETED", value: "MARKETED" },
                  {
                    label: "CALL CENTRE / WALK IN",
                    value: "CALL CENTRE / WALK IN",
                  },
                  { label: "REPEAT", value: "REPEAT" },
                ]}
                onChange={(options) => {
                  setClientType(options);
                }}
              />
            </div>
            <div className="w-50">
              <label>Reffered By</label>

              <input
                className="w-100"
                type="text"
                id="referred_by"
                placeholder="Reffered By"
              />
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="w-50 mr-3">
              <label>Category</label>
              <Select
                options={[
                  { label: "Retail/Hospitality", value: "Retail/Hospitality" },
                  { label: "HNI", value: "HNI" },
                  { label: "Builder", value: "Builder" },
                  { label: "PMC", value: "PMC" },
                  { label: "Architect", value: "Architect" },
                ]}
                onChange={(options) => {
                  setCategory(options);
                }}
              />
            </div>
            <div className="w-50">
              <label>GST No.</label>

              <input className="w-100" type="number" id="gst_no" />
            </div>
          </div>
          <div className="d-flex mt-3 ">
            <div className="w-50 mr-3">
              <label>VAT No.</label>

              <input className="w-100" type="number" id="vat_no" />
            </div>
            <div className="w-50">
              <label>Pan No.</label>

              <input className="w-100" type="number" id="pan_no" />
            </div>
          </div>
          <div className="d-flex mt-3">
            <div className="w-50 mr-3">
              <label>Site Contact Number</label>

              <input
                type="number"
                id="site_contact_input"
                placeholder="Site Contact Number"
                required
                className="w-100"
                min={1111111111}
                max={9999999999}
              />
            </div>
            <div className="w-50">
              <label>Work to be done</label>

              <Select
                required
                id="checklist__select"
                onChange={(options) => {
                  setClientWorks((state) => setClientWorks(options));
                }}
                isMulti
                className="mt-2"
                options={[
                  { value: "1", label: "Survey" },
                  { value: "2", label: "Installation" },
                  { value: "3", label: "Quality Assurance" },
                ]}
              />
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary">
              Add New Client
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewClientModal;
