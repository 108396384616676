import React, { Fragment, useState } from 'react'
import Card from '../components/UI/card/Card'
import {BsGraphUp} from 'react-icons/bs'
import {MdOutlineTask} from 'react-icons/md'
const UserDashboard = () => {

    const [userStats, setUserStats] = useState('weekly');
  return (
    <Fragment>
        <section className='p-20px'>
            <Card className='pt-2 pb-2'>
                    <div className='d-flex mb-2'>
                        <span className='pl-2 user_dashboard__heading'><BsGraphUp className='mr-1'/> My Statistics</span>
                        <span onClick={()=> setUserStats('weekly')} className={'float-end ml-auto mr-2 '+ (userStats == 'weekly' ? ' active_user_time' : '') }> Weekly</span>
                        <span onClick={()=> setUserStats('monthly')} className={'float-end  mr-2 '+ (userStats == 'monthly' ? ' active_user_time' : '') } > Monthly</span>
                    </div>
                    <div className='d-grid-50-50-card p-20px'>
                        <Card className='text-center p-2'>
                            <MdOutlineTask  className='mb-2 font-size-25px svg_color_blue'/>
                            <h2 className='mb-2 color-gradient'>100</h2>
                            <p>Completed</p>
                        </Card>
                        <Card className='text-center p-2'>
                            <MdOutlineTask  className='mb-2 font-size-25px'/>
                            <h2 className='mb-2 color-gradient'>100</h2>
                            <p>Completed</p>
                        </Card>
                        <Card className='text-center p-2'>
                            <MdOutlineTask  className='mb-2 font-size-25px'/>
                            <h2 className='mb-2 color-gradient'>100</h2>
                            <p>Completed </p>
                        </Card>
                        <Card className='text-center p-2'>
                            <MdOutlineTask  className='mb-2 font-size-25px'/>
                            <h2 className='mb-2 color-gradient'>100</h2>
                            <p>Completed </p>
                        </Card>
                    </div>
            </Card>
        </section>
    </Fragment>
  )
}

export default UserDashboard