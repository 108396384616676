import axios from "axios";
import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import TaskListLoader from "./loaders/TaskListLoader";

const User = () => {
  // const baseUrl = "http://206.189.141.201/api/";
  // const baseUrl = "http://127.0.0.1:8000/api/";

  const { id } = useParams();
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

// const navigate = Navigate();
  useEffect(() => {
    axios
      .get(baseUrl + "getUserDetails/android/app?user_token=" + id, {
        headers: {
          accesstoken: id,
          Authorization: "Basic YWRtaW46MTIzNA==",
        },
      })
      .then((response) => {
        console.log(response);
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_role");

        localStorage.removeItem("user_token");
        localStorage.setItem("user_name", response.data.data[0].name);
        localStorage.setItem("user_id", response.data.data[0].user_id);
        localStorage.setItem("user_token", id);
        window.open("http://app.workcompanion.co/user-task", "_self")
        window.location = '/user-task';
      });
  }, [id]);
  return <div>
    <TaskListLoader/>
  </div>;
};

export default User;
