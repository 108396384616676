import React, { useEffect } from "react";
import classes from "./GroundWorkerClients.module.css";
import { TbMapSearch } from "react-icons/tb";
import { FiPhoneCall } from "react-icons/fi";
import Card from "../UI/card/Card";
import { useState } from "react";
import axios from "axios";
import UserTask from "../UI/tasks/UserTask";
import { Link } from "react-router-dom";
const GroundWorkerClients = (props) => {
  const [showTasks, setShowTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  useEffect(() => {
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .post(
        baseUrl + "getUserAssignedChecklist",
        {
          client_id: props.checklists.id,
          // checklist_id: props.checklists.checklist_id,
          user_id: localStorage.getItem("user_id"),
        },
        {
          headers: {
            accesstoken: localStorage.getItem("user_token"),
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setTasks(response.data.data);
        document.getElementById("fullPageLoader").classList.add("d-none");
        return;
      });
  }, []);
  return (
    <Card className={"mb-2 p-2"}>
      <div className={classes.ground_worker_task_div}>
        <div>
          <h2 className="mb-2">{props.checklists.name}</h2>
          <p className="mb-2">{props.checklists.address}</p>
          <p
            className="text-primary fw-500 "
            onClick={() => {
              setShowTasks((state) => {
                return !state;
              });
            }}
          >
            Show Checklist
          </p>
        </div>
        <div>
          <p className={classes.date_userClient + " mb-2"}></p>
          <p>
            <span className="mr-2">
              <a href="#">
                <TbMapSearch />
              </a>
            </span>
            <span>
              <a href={"tel:" + props.checklists.contact}>
                <FiPhoneCall />
              </a>
            </span>
          </p>
        </div>
      </div>
      {showTasks && (
        <div>
          {tasks
            ? tasks.map((value) => {
                return (
                  <h3 className="mt-2">
                    <Link
                      to={"/user/checklist/" + value.client_id + "/" + value.id}
                    >
                      {value.name}
                    </Link>
                  </h3>
                );
              })
            : "No checklist found"}
        </div>
      )}
    </Card>
  );
};

export default GroundWorkerClients;
