import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { FiSettings } from "react-icons/fi";
import { RiAddCircleFill } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import Card from "../components/UI/card/Card";
import Task from "../components/UI/tasks/Task";
import Sidebarclasses from "../components/sidebar/Sidebar.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Select from "react-select";
import TaskListLoader from "../components/loaders/TaskListLoader";
import ClientNameLoader from "../components/loaders/ClientNameLoader";
import ProgressBar from "@ramonak/react-progress-bar";
const ChecklistTasks = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const [tasks, setTasks] = useState([]);
  const [clientDetials, setClientDetials] = useState([]);
  const { client_id, checklist_id } = useParams();
  const [currentAssignee, SetCurrentAssignee] = useState([]);
  const [departmentUser, SetDepartmentUsers] = useState([]);
  const [newAssignee, SetNewAssignee] = useState(0);
  const [Progress, SetProgress] = useState(null);
  // var departmentUser = [];
  const addNewTask = (e) => {
    e.preventDefault();
    var due_date = document.getElementById("task_date_input").value;
    var title = document.getElementById("task_name_input").value;
    document.getElementById("fullPageLoader").classList.remove("d-none");

    axios
      .post(
        baseUrl + "create-todo",
        {
          client_id,
          checklist_id,
          due_date,
          title,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        var task = response.data.data;
        setTasks(task);
        Toast.fire({
          icon: "success",
          text: "Task Created Successfully",
        });
        document.getElementById("fullPageLoader").classList.add("d-none");
        addNewTaskModal();
        return;
      });
  };

  const updateDueDate = (e) => {
    const id = e.target.dataset.id;
  };

  const addNewTaskModal = () => {
    document.getElementById("add_todo_modal").classList.toggle("d-none");
    document.getElementById("add_new_client_form").reset();
  };

  const changeAssigneeModal = () => {
    document.getElementById("change_assignee_modal").classList.toggle("d-none");
  };
  const downloadReport = () => {
    axios
      .post(
        baseUrl + "downloadChecklistReport",
        {
          client_id,
          checklist_id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        console.log(response);
        var a = document.createElement('a');
            a.href = response.data.file;
            a.target = '_blank';
            a.download = response.data.name + '.xlsx';
            a.click();
        // const url = URL.createObjectURL(new Blob([response.data.file]), );
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', 'filename.xlsx');
        // document.body.appendChild(link);
        // link.click();
        return;
      });
  };

  const changeAssignee = (e) => {
    e.preventDefault();
    document.getElementById("change_assignee_modal").classList.add("d-none");
    if (newAssignee) {
      Swal.fire({
        title: "Are you sure you want to change assignee?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("fullPageLoader").classList.remove("d-none");

          axios
            .post(
              baseUrl + "changeAssigneeChecklist",
              {
                client_id,
                checklist_id,
                assignee_id: newAssignee,
              },
              {
                headers: {
                  accesstoken:
                    "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                  Authorization: "Basic YWRtaW46MTIzNA==",
                },
              }
            )
            .then((response) => {
              Toast.fire({
                icon: "success",
                title: "Assignee Updated Successfully",
              });
              document.getElementById("fullPageLoader").classList.add("d-none");

              return;
            });
        }
      });
    } else {
      Toast.fire({
        icon: "error",
        title: "Unknown Error Occured",
      });
    }
  };
  useEffect(() => {
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .post(
        baseUrl + "get-todo-detials",
        {
          client_id,
          checklist_id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setTasks(response.data.data);
        var count = 0;
        response.data.data.map((value) => {
          if (value.status == 1) {
            count++;
          }
        });
        SetProgress(Math.round((count / response.data.data.length) * 100));
        return;
      });

    axios
      .post(
        baseUrl + "getChecklistAssignedDetails",
        {
          client_id,
          checklist_id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        setClientDetials(response.data.data[0]);
        SetCurrentAssignee({
          label: `${response.data.data[0].user_name}`,
          value: `${response.data.data[0].assignee_id}`,
        });
        axios
          .post(
            baseUrl + "getDepartmentUsers",
            {
              user_id: response.data.data[0].assignee_id,
            },
            {
              headers: {
                accesstoken:
                  "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                Authorization: "Basic YWRtaW46MTIzNA==",
              },
            }
          )
          .then((response) => {
            SetDepartmentUsers([]);
            response.data.data.map((value) => {
              SetDepartmentUsers((state) => [
                ...state,
                { label: value.user_name, value: value.user_id },
              ]);
            });
          });
        document.getElementById("fullPageLoader").classList.add("d-none");
        return;
      });
  }, [checklist_id, client_id, newAssignee]);

  return (
    <div className="w-100">
      <nav className="w-100 client_overview__nav ">
        <div className="d-flex w-100">
          <div className="p-relative d-flex">
            <h2 className="mr-2">
              {clientDetials?.checklist_name} /{" "}
              <span className="font-size-16 text-muted">
                {clientDetials?.client_name} ({clientDetials?.user_name})
              </span>
            </h2>
          </div>
          <span className="ml-auto mr-3" onClick={changeAssigneeModal}>
            <FiSettings
              style={{ float: "left", fontSize: "20px" }}
              className="mr-1"
            />{" "}
            Edit Assignee
          </span>

          <span title="Add New Task">Add New Task</span>
          <RiAddCircleFill
            className="float-end svg_blue ml-2"
            title="Add New Task"
            onClick={addNewTaskModal}
          />
        </div>
      </nav>
      <div className="p-5 d-flex">
        {Progress >= 0 ? (
          <ProgressBar className="progress_bar w-80" completed={Progress} />
        ) : (
          <ClientNameLoader />
        )}
        <button className="btn btn-primary" onClick={downloadReport}>Download Report</button>
      </div>
      <div className="p-5 ">
        {tasks?.map((value) => {
          return (
            <Card key={"checlist_task_" + value.id} className="p-2 mb-3 d-flex">
              <Task
                id={value.id}
                title={value.title}
                dueDate={value.due_date}
                status={value.status}
              />
            </Card>
          );
        })}
      </div>
      <div
        className={Sidebarclasses.client_modal + " d-none"}
        id="add_todo_modal"
      >
        <div className={Sidebarclasses.modal_heading}>
          <h2>
            Add New Task <AiOutlineCloseCircle onClick={addNewTaskModal} />
          </h2>
        </div>
        <div className={Sidebarclasses.modal_body}>
          <form onSubmit={addNewTask} id="add_new_client_form">
            <div>
              <label>Task Name</label>
              <input
                type="text"
                placeholder="Task Name"
                id="task_name_input"
                required
              />
            </div>

            <div>
              <label>Due date</label>
              <input
                type="datetime-local"
                placeholder="Task Date"
                id="task_date_input"
                required
              />
            </div>

            {/* <div>
              <label>Add task to </label>

              <Select
                id="checklist__select"
                style={{width: '70%'}}
                className='mt-2'
                // onChange={}
                options={[
                  { value: "1", label: "Add to All " },
                  { value: "2", label: "Add to Uncompleted" },
                  { value: "3", label: "Add to only this " },
                ]}
              />
            </div> */}
            <div>
              <button type="submit">Add Task</button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={Sidebarclasses.client_modal + " d-none"}
        id="change_assignee_modal"
      >
        <div className={Sidebarclasses.modal_heading}>
          <h2>
            Change Assignee
            <AiOutlineCloseCircle onClick={changeAssigneeModal} />
          </h2>
        </div>
        <div className={Sidebarclasses.modal_body}>
          <form onSubmit={changeAssignee} id="change_assginee_form">
            <div className="mb-2">
              <label>Current Assignee</label>
              <p> {currentAssignee.label}</p>
            </div>
            <div>
              <label>Change Assignee</label>
              <Select
                options={departmentUser}
                onChange={(value) => {
                  SetNewAssignee(value.value);
                }}
              />
            </div>
            <div>
              <button type="submit">Change Assignee</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChecklistTasks;
