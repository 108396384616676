import React, { Fragment, useEffect, useState } from "react";
import classes from "./Task.module.css";
import { GrCompliance } from "react-icons/gr";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import axios from "axios";
import Card from "../card/Card";
const Task = (props) => {
  const [isCompleted, setIsCompleted] = useState(props.status);
  // const [showDetails, ] = useState(0);
  const setshowDetails = (e) => {
    var taskDetailsID = e.target.dataset.id;
    document.getElementById("details_loader").classList.remove("d-none");
    var t = "";
    document
      .getElementById("edit_task_details__div")
      .classList.toggle("d-none");
    document.getElementById("details_remarks_div").innerHTML = "";
    axios
      .post(
        baseUrl + "update-todo-getremark",
        {
          todo_detail_id: taskDetailsID,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        console.log();
        response.data.data.map((value) => {
          t =
            t +
            ` <div className="mt-3 ml-3 p-20px">
          <h3> ${value.remark}</h3>
        </div>`;
        });
        document.getElementById("details_remarks_div").innerHTML = t;
        document.getElementById("details_loader").classList.add("d-none");
      });
  };

  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const Toast = Swal.mixin({
    toast: true,
    position: "top-bottom",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const updateDate = (due_date, todo_detail_id) => {
    document.getElementById("fullPageLoader").classList.remove("d-none");

    axios
      .post(
        baseUrl + "update-todo-duedate",
        {
          due_date,
          todo_detail_id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        document.getElementById("fullPageLoader").classList.add("d-none");

        if (response.status == "200") {
          Toast.fire({
            icon: "success",
            title: "Task Updated Successfully",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Unable to Update",
          });
        }
      });
  };

  const tasKCompleted = (e) => {
    if (!e.target.dataset.id) {
      e.preventDefault();
      return;
    }
    Swal.fire({
      title: "Select Task State",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const { value: formValues } = Swal.fire({
          title: "Task Completed Details",
          html:
            '<form><textarea id="swal-input1" class="swal2-textarea" row="3" placeholder="Remarks" required> </textarea>' +
            '<input type="file" multiple id="swal-input2" class="swal2-file"><form/>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").files,
            ];
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (result.value[1].length) {
              for (var i = 0; i < result.value[1].length; i++) {
                var formData = new FormData();
                formData.append(
                  "file",
                  result.value[1][i],
                  result.value[1][i].name
                );
                formData.append("todo_detail_id", e.target.dataset.id);
                formData.append("user_id", localStorage.getItem("user_id"));
                axios
                  .post(
                    baseUrl + "checklist/client/uploadFileChecklist/",
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        accesstoken: localStorage.getItem("user_token"),
                        Authorization: "Basic YWRtaW46MTIzNA==",
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.status == "200") {
                      Toast.fire({
                        icon: "success",
                        title: "File Uploaded Successfully",
                      });
                    }
                  });
              }
            }
            axios
              .post(
                baseUrl + "update-todo-status",
                {
                  todo_detail_id: e.target.dataset.id,
                },
                {
                  headers: {
                    accesstoken:
                      "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                    Authorization: "Basic YWRtaW46MTIzNA==",
                  },
                }
              )
              .then((response) => {
                document
                  .getElementById("fullPageLoader")
                  .classList.remove("d-none");

                axios
                  .post(
                    baseUrl + "update-todo-remark/",
                    {
                      todo_detail_id: e.target.dataset.id,
                      remark: result.value[0],
                      user_id: localStorage.getItem("user_id"),
                    },
                    {
                      headers: {
                        accesstoken:
                          "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                        Authorization: "Basic YWRtaW46MTIzNA==",
                      },
                    }
                  )
                  .then((response) => {
                    document
                      .getElementById("fullPageLoader")
                      .classList.add("d-none");

                    Toast.fire({
                      icon: "success",
                      title: "Task Updated Successfully",
                    });
                    setIsCompleted(1);
                  });
              });
          }
        });
      } else {
        const { value: formValues } = Swal.fire({
          title: "Task Completed Details",
          html:
            '<form><textarea id="swal-input1" class="swal2-textarea" row="3" placeholder="Remarks" required> </textarea>' +
            '<input type="file" multiple id="swal-input2" class="swal2-file"> ' +
            '<input type="datetime-local" id="swal-input3" class="swal2-file"><form/>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").files,
              document.getElementById("swal-input3").value,
            ];
          },
        }).then((result) => {
          if (result.value[1].length) {
            for (var i = 0; i < result.value[1].length; i++) {
              var formData = new FormData();
              formData.append(
                "file",
                result.value[1][i],
                result.value[1][i].name
              );
              formData.append("todo_detail_id", e.target.dataset.id);
              formData.append("user_id", localStorage.getItem("user_id"));
              console.log(formData.get("file"));
              axios
                .post(
                  baseUrl + "checklist/client/uploadFileChecklist/",

                  formData,

                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      accesstoken: localStorage.getItem("user_token"),
                      Authorization: "Basic YWRtaW46MTIzNA==",
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status == "200") {
                    Toast.fire({
                      icon: "success",
                      title: "File Uploaded Successfully",
                    });
                  }
                });
            }
          }
          if (result.value[2] !== "") {
            updateDate(result.value[2], e.target.dataset.id);
          }
          document.getElementById("fullPageLoader").classList.remove("d-none");

          axios
            .post(
              baseUrl + "update-todo-remark/",
              {
                todo_detail_id: e.target.dataset.id,
                remark: result.value[0],
                user_id: localStorage.getItem("user_id"),
              },
              {
                headers: {
                  accesstoken:
                    "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                  Authorization: "Basic YWRtaW46MTIzNA==",
                },
              }
            )
            .then((response) => {
              document.getElementById("fullPageLoader").classList.add("d-none");

              if (response.status == "200") {
                Toast.fire({
                  icon: "success",
                  title: "Task Updated Successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: "Unable to Update",
                });
              }
            });
          setIsCompleted(0);

          // if (result.isConfirmed) {
          //   if (result.value[1]) {
          //     axios
          //       .patch(baseUrl + "todoDetails/" + props.detailsid, {
          //         due_date: result.value[1],
          //       })
          //       .then((response) => {
          //         if (response.statusText == "OK") {
          //           Toast.fire({
          //             icon: "success",
          //             title: "Task Updated Successfully",
          //           });
          //         } else {
          //           Toast.fire({
          //             icon: "error",
          //             title: "Unable to Update",
          //           });
          //         }
          //       });
          //     Toast.fire({
          //       icon: "success",
          //       title: "Remark Noted and Rescheduled Successfully",
          //     });
          //   } else {
          //     axios
          //       .patch(baseUrl + "ClientChecklistRemark/" + props.remarksid, {
          //         remark: result.value[0],
          //         status: 0,
          //       })
          //       .then((response) => {
          //         if (response.statusText == "OK") {
          //           Toast.fire({
          //             icon: "success",
          //             title: "Remark Noted",
          //           });
          //         } else {
          //           Toast.fire({
          //             icon: "error",
          //             title: "Unable to Update",
          //           });
          //         }
          //       });
          //   }
          // }
        });
      }
    });
  };
  const addAssignee = (e) => {
    axios
      .patch(baseUrl + "todoDetails/" + props.detailsid, {
        assignee: e.value,
      })
      .then((response) => {
        if (response.status == "200") {
          Toast.fire({
            icon: "success",
            title: "Task Updated Successfully",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Unable to Update",
          });
        }
      });
  };

  return (
    <Card className="p-2 mb-2">
      <div
        className={
          classes.single_task +
          " w-100 " +
          (isCompleted ? " task_completed" : "")
        }
        id={props.id}
        data-id={props.id}
        data-completed={isCompleted}
      >
        <div className={classes.task_title_div}>
          <div
            // onClick={tasKCompleted.bind(this)}
            data-id={props.id}
            className={
              classes.task_complete_icon +
              (isCompleted == 1 ? " " : ` ${classes.completed_task}`)
            }
          >
            <GrCompliance
              onClick={tasKCompleted.bind(this)}
              data-id={props.id}
              className=" font-size-25px"
            />
          </div>
          <div className={classes.task_title + " w-90"} data-id={props.id}>
            <h3>{props.title}</h3>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Task;
