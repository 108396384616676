import { createSlice } from "@reduxjs/toolkit";

const initialState = { auth: true, userType: null };

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, actions) => {
     state.auth = true;
     state.userType = actions.payload.type
    },
    logout: (state) => {
     state.auth = false;
     state.userType = null;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
