import React, { Fragment, useEffect, useState } from "react";
import classes from "./Task.module.css";
import { GrCompliance } from "react-icons/gr";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import axios from "axios";
const Task = (props) => {
  const [isCompleted, setIsCompleted] = useState(props.status);
  // const [showDetails, ] = useState(0);
  const setshowDetails = (e) => {
    var taskDetailsID = e.target.dataset.id;
    document.getElementById("details_loader").classList.remove("d-none");
    var t = "";
    document
      .getElementById("edit_task_details__div")
      .classList.toggle("d-none");
    document.getElementById("details_remarks_div").innerHTML = "";
    axios
      .post(
        baseUrl + "update-todo-getremark",
        {
          todo_detail_id: taskDetailsID,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          response.data.data?.map((value) => {
            t =
              t +
              ` <div className="mt-3 ml-3 p-20px">
            <h3> ${value.remark} - ${value.name}</h3>
          </div>`;
          });
          axios
            .get(
              baseUrl + "getTodoDetailsImages?todo_details_id=" + taskDetailsID,
              {
                headers: {
                  accesstoken:
                    "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                  Authorization: "Basic YWRtaW46MTIzNA==",
                },
              }
            )
            .then((response) => {
              response?.data?.data.map((value) => {
                t =
                  t +
                  ` <div class="mt-3 ml-3 d-flex align-items-center">
                <img class="w-25" src="https://woco.sgp1.digitaloceanspaces.com/uploads/task_img/wocollp/${value.file}"/> <span class='font-bold'>- ${value.user_name}</span>
          </div>`;
              });
              document.getElementById("details_remarks_div").innerHTML = t;
            });
        } else {
          t = '<p className="mt-3 ml-3 p-20px"> No Detials Found</p>';
        }

        document.getElementById("details_remarks_div").innerHTML = t;
        document.getElementById("details_loader").classList.add("d-none");
      });
  };
  // const baseUrl = "http://127.0.0.1:8000/api/";

  // const baseUrl = "http://206.189.141.201/api/";
  // const baseUrl = "http://wocollp.localhost/wocodev/api-v2/";
  const baseUrl = "https://wocollp.workcompanion.co/api-v2/";

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const updateDate = (e) => {
    document.getElementById("fullPageLoader").classList.remove("d-none");
    axios
      .post(
        baseUrl + "update-todo-duedate",
        {
          due_date: e.target.value,
          todo_detail_id: e.target.dataset.id,
        },
        {
          headers: {
            accesstoken:
              "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
            Authorization: "Basic YWRtaW46MTIzNA==",
          },
        }
      )
      .then((response) => {
        if (response.status == "200") {
          Toast.fire({
            icon: "success",
            title: "Task Updated Successfully",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Unable to Update",
          });
        }
        document.getElementById("fullPageLoader").classList.add("d-none");
      });
  };

  const tasKCompleted = (e) => {
    if (!e.target.dataset.id) {
      return;
    }
    Swal.fire({
      title: "Select Task State",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const { value: formValues } = Swal.fire({
          title: "Task Completed Details",
          html:
            '<form><textarea id="swal-input1" class="swal2-textarea" row="3" placeholder="Remarks" required> </textarea>' +
            '<input type="file" id="swal-input2" class="swal2-file"><form/>',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value,
            ];
          },
        }).then((result) => {
          if (result.isConfirmed) {
            document
              .getElementById("fullPageLoader")
              .classList.remove("d-none");
            axios
              .post(
                baseUrl + "update-todo-status",
                {
                  todo_detail_id: e.target.dataset.id,
                },
                {
                  headers: {
                    accesstoken:
                      "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                    Authorization: "Basic YWRtaW46MTIzNA==",
                  },
                }
              )
              .then((response) => {
                axios
                  .post(
                    baseUrl + "update-todo-remark/",
                    {
                      todo_detail_id: e.target.dataset.id,
                      remark: result.value[0],
                    },
                    {
                      headers: {
                        accesstoken:
                          "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                        Authorization: "Basic YWRtaW46MTIzNA==",
                      },
                    }
                  )
                  .then((response) => {
                    document
                      .getElementById("fullPageLoader")
                      .classList.add("d-none");

                    if (response.status == "200") {
                      Toast.fire({
                        icon: "success",
                        title: "Task Updated Successfully",
                      });
                    } else {
                      Toast.fire({
                        icon: "error",
                        title: "Unable to Update",
                      });
                    }
                  });
              });
            setIsCompleted(1);
          } else {
            document.getElementById("fullPageLoader").classList.add("d-none");
          }
        });
      } else {
        const { value: formValues } = Swal.fire({
          title: "Task Completed Details",
          html: '<form><textarea id="swal-input1" class="swal2-textarea" row="3" placeholder="Remarks" required> </textarea>',
          //  + '<input type="date" id="swal-input2" class="swal2-file"><form/>'
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              // document.getElementById("swal-input2").value,
            ];
          },
        }).then((result) => {
          if(result.isDismissed){
            document.getElementById("fullPageLoader").classList.add("d-none");
            return;
          }
          document.getElementById("fullPageLoader").classList.remove("d-none");
          axios
            .post(
              baseUrl + "update-todo-remark/",
              {
                todo_detail_id: e.target.dataset.id,
                remark: result.value[0],
              },
              {
                headers: {
                  accesstoken:
                    "WUhWWEpGUjE3TDBTS1FURDIzWTBBUTZQRUNRNk9DUkJVU1VBSUxPMjhYNUQyQ1A1Rkc0ODBFQzdFWjk4RktEUA",
                  Authorization: "Basic YWRtaW46MTIzNA==",
                },
              }
            )
            .then((response) => {
              document.getElementById("fullPageLoader").classList.add("d-none");

              if (response.status == "200") {
                Toast.fire({
                  icon: "success",
                  title: "Task Updated Successfully",
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: "Unable to Update",
                });
              }
            });
          document.getElementById("fullPageLoader").classList.add("d-none");

          setIsCompleted(0);

          // if (result.isConfirmed) {
          //   if (result.value[1]) {
          //     axios
          //       .patch(baseUrl + "todoDetails/" + props.detailsid, {
          //         due_date: result.value[1],
          //       })
          //       .then((response) => {
          //         if (response.statusText == "OK") {
          //           Toast.fire({
          //             icon: "success",
          //             title: "Task Updated Successfully",
          //           });
          //         } else {
          //           Toast.fire({
          //             icon: "error",
          //             title: "Unable to Update",
          //           });
          //         }
          //       });
          //     Toast.fire({
          //       icon: "success",
          //       title: "Remark Noted and Rescheduled Successfully",
          //     });
          //   } else {
          //     axios
          //       .patch(baseUrl + "ClientChecklistRemark/" + props.remarksid, {
          //         remark: result.value[0],
          //         status: 0,
          //       })
          //       .then((response) => {
          //         if (response.statusText == "OK") {
          //           Toast.fire({
          //             icon: "success",
          //             title: "Remark Noted",
          //           });
          //         } else {
          //           Toast.fire({
          //             icon: "error",
          //             title: "Unable to Update",
          //           });
          //         }
          //       });
          //   }
          // }
        });
      }
      document.getElementById("fullPageLoader").classList.add("d-none");
    });
  };
  const addAssignee = (e) => {
    axios
      .patch(baseUrl + "todoDetails/" + props.detailsid, {
        assignee: e.value,
      })
      .then((response) => {
        if (response.status == "200") {
          Toast.fire({
            icon: "success",
            title: "Task Updated Successfully",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Unable to Update",
          });
        }
      });
  };

  return (
    <Fragment>
      <div
        className={
          classes.single_task +
          " w-100 " +
          (isCompleted ? " task_completed" : "")
        }
        id={props.id}
        data-completed={isCompleted}
      >
        <div className={classes.task_title_div}>
          <div
            onClick={tasKCompleted.bind(this)}
            data-id={props.id}
            className={
              classes.task_complete_icon +
              (isCompleted == 1 ? " " : ` ${classes.completed_task}`)
            }
          >
            <GrCompliance data-id={props.id} className="w-100 font-size-25px" />
          </div>
          <div className={classes.task_title + " w-50"} data-id={props.id}>
            <h3>{props.title}</h3>
          </div>
        </div>

        <div className="d-flex">
          <div
            className="ml-auto mr-3"
            data-id={props.id}
            onClick={setshowDetails.bind(this)}
          >
            Details
          </div>

          <div className=" mr-3">
            <input
              name="due_date"
              defaultValue={props.dueDate}
              type="datetime-local"
              onBlur={updateDate.bind(this)}
              data-id={props.id}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Task;
